@use 'common.scss';

section.redirect {
  padding: 16px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100 - 32px);

  div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 7rem;
    margin: 0 0 -16px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0 0 48px;
  }

  p {
    align-self: flex-end;
  }
}
