$bg-dark: #1c1c1c;
$bg-dark-contrast: #f2f2f2;
$bg-light: #f2f2f2;
$bg-light-contrast: #1c1c1c;
$grey: #8c8c88;
$primary-dark: #306f7d;
$primary-light: #aad7da;
$accent: #f25e5e;

html,
body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  background-color: $bg-light;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: $bg-light-contrast;
}

html[lang='de'] .en {
  display: none !important;
}

html[lang='en'] .de {
  display: none !important;
}

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #{$accent};
  transition: box-shadow 0.2s ease;
}

::selection {
  background-color: $accent;
  background-color: #{$accent}69;
}

::-moz-selection {
  background-color: $accent;
  background-color: #{$accent}69;
}

.pointer {
  cursor: pointer;
}

.hover-transform {
  display: block;
  transition: scale 0.3s ease;
  cursor: none;

  &:hover {
    scale: 1.025;
  }
}

section.container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  div.content {
    width: 100%;
    max-width: 1200px;
  }

  h2 {
    font-size: 40px;
    margin: 0;
    font-weight: 600;
  }

  h3 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
  }

  @media screen and (min-width: 500px) {
    h2 {
      font-size: 8vw;
    }

    h3 {
      font-size: 4.8vw;
    }
  }

  @media screen and (min-width: 1000px) {
    h2 {
      font-size: 80px;
    }

    h3 {
      font-size: 48px;
    }
  }
}

p {
  line-height: 1.5;
}

a,
a:link {
  color: inherit;
  text-decoration: none;
  background-position-y: -0%;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 45%, $accent 45%);
  transition: background 500ms ease;
  background-size: 2px;
  background-size: auto 155%;
}

a:hover {
  background-position-y: 100%;
}

@keyframes reveal {
  from {
    opacity: 0;
    scale: 0.9;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
